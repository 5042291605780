export const environment = {
  PRODUCTION: false,
  ENVIRONMENT: 'QA',
  LOCATION: 'ESP',
  apiUrl: {
    auth: 'https://kadabra.involverh.com/auth',
    vacancy: 'https://kadabra.involverh.com/vacancy',
    management: 'https://kadabra.involverh.com/management',
    user: 'https://kadabra.involverh.com/user',
    psychometric: 'https://kadabra.involverh.com/psychometric',
    files: 'https://kadabra.involverh.com/files',
    ia: 'https://kadabra.ia.involverh.com',
    iaAssistant: 'https://kadabra.ia.involverh.com/files',
  },
  recruiterUrl: 'https://involveprereclu.involverh.es/',
  candidateUrl: 'https://involveprecan.involverh.es',
  stripePublicKey: {
    MEX: 'pk_test_51MTqAzJCQT8rhUScfg4lDFpqtnpQ0YCPw54byaELUz3xB1rvjnWF2vYs5GRv74n7TRQ8Su9qJIeQSFTh76S6Se8w00HbVAHmsw',
    ESP: 'pk_test_51NW129KXFrczkLHvvYk3L9qYKXo3DgMTp7z5bpxU3dvvHZlkdyTjBtiywF38Ex5aX7wMJdpDFWG5gaP94t2KsD6f00Drh0rQGa',
  },
};
